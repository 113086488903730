import { AfterContentInit, afterRender, AfterRenderPhase, AfterViewInit, Directive, ElementRef, HostBinding, OnChanges, OnInit, PLATFORM_ID, Renderer2, SecurityContext, SimpleChanges, ViewChild, input, inject } from "@angular/core";
import MarkdownIt from "markdown-it";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {isPlatformServer} from '@angular/common';
//import * as markdownItTable from "markdown-it-table";
//import * as markdownCheckbox from "markdown-it-task-checkbox";


const MD = MarkdownIt({
  html: true,
  breaks: true,
});
  //.use(markdownItTable as any)
  //.use(markdownCheckbox as any)
  /*.use(mdCehckboxPlugin, {
    disabled: false,
    divWrap: true,
    divClass: 'md-tasklist-checkbox',
    ulClass: 'md-tasklist',
    liClass: 'md-tasklist-item'
  });*/

@Directive({
  standalone: true,
  host: {
    class: 'ui-md'
  },
  selector: '[ui-markdown]'
})
export class MarkdownDirective implements OnInit, OnChanges {
  private sanitizer = inject(DomSanitizer);
  private renderer = inject(Renderer2);
  private el = inject(ElementRef);
  private platformId = inject<Object>(PLATFORM_ID);

  readonly text = input<string>('', { alias: "ui-markdown" });

  ngOnInit() {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ui-markdown'] && changes['ui-markdown'].currentValue !== changes['ui-markdown'].previousValue) {
      this.render();
    }
  }

  private render() {
    const el = this.el.nativeElement;

    // For server side insert plain text, because inserting using innerHTML
    // will create new DOM nodes and breaks hydration
    if (isPlatformServer(this.platformId)) {
      this.renderer.setProperty(el, 'textContent', this.text() || '');
      return;
    }

    // On client - render markdown and insert using innerHTML
    const txt = MD.render(this.text() || '');
    const html= this.sanitizer.sanitize(SecurityContext.HTML, txt) ?? '';
    this.renderer.setProperty(el, 'innerHTML', html);
  }
}

function mdCehckboxPlugin(md: any, options: any) {
  let defaults = {
    disabled: true,
    divWrap: false,
    divClass: 'checkbox',
    idPrefix: 'cbx_',
    ulClass: 'task-list',
    liClass: 'task-list-item'
  };
  options = Object.assign({}, defaults, options);
  md.core.ruler.after('inline', 'github-task-lists', function (state: any) {
    const tokens = state.tokens;
    let lastId = 0;
    for (let i = 2; i < tokens.length; i++) {

      if (isTodoItem(tokens, i)) {
        todoify(tokens[i], lastId, options, state.Token);
        lastId += 1;
        attrSet(tokens[i - 2], 'class', options.liClass);
        attrSet(tokens[parentToken(tokens, i - 2)], 'class', options.ulClass);
      }
    }
  });
}

function attrSet(token: any, name: any, value: any) {
  const index = token.attrIndex(name);
  const attr = [name, value];

  if (index < 0) {
    token.attrPush(attr);
  } else {
    token.attrs[index] = attr;
  }
}

function parentToken(tokens: any, index: any) {
  const targetLevel = tokens[index].level - 1;
  for (let i = index - 1; i >= 0; i--) {
    if (tokens[i].level === targetLevel) {
      return i;
    }
  }
  return -1;
}

function isTodoItem(tokens: any, index: any) {
  return isInline(tokens[index]) &&
    isParagraph(tokens[index - 1]) &&
    isListItem(tokens[index - 2]) &&
    startsWithTodoMarkdown(tokens[index]);
}

function todoify(token: any, lastId: any, options: any, TokenConstructor: any) {
  let id;
  id = options.idPrefix + lastId;
  const checked = /^\[[xX]\][ \u00A0]/.test(token.content);
  token.children[0].content = token.children[0].content.slice(3);
  // label
  token.children.unshift(beginLabel(id, TokenConstructor));
  token.children.push(endLabel(TokenConstructor));
  // checkbox
  token.children.unshift(makeCheckbox(token, id, options, TokenConstructor));
  if (options.divWrap) {
    const divClass = options.divClass;
    if (checked) {
      options.divClass = options.divClass + ' checked';
    }
    token.children.unshift(beginWrap(options, TokenConstructor));
    token.children.push(endWrap(TokenConstructor));
    options.divClass = divClass;
  }
}

function makeCheckbox(token: any, id: any, options: any, TokenConstructor: any) {
  const checkbox = new TokenConstructor('checkbox_input', 'input', 0);
  checkbox.attrs = [['type', 'checkbox'], ['id', id]];
  const checked = /^\[[xX]\][ \u00A0]/.test(token.content); // if token.content starts with '[x] ' or '[X] '
  if (checked === true) {
    checkbox.attrs.push(['checked', 'true']);
  }
  if (options.disabled === true) {
    checkbox.attrs.push(['disabled', 'true']);
  }

  return checkbox;
}

function beginLabel(id: any, TokenConstructor: any) {
  const label = new TokenConstructor('label_open', 'label', 1);
  label.attrs = [['for', id]];
  return label;
}

function endLabel(TokenConstructor: any) {
  return new TokenConstructor('label_close', 'label', -1);
}

// these next two functions are kind of hacky; probably should really be a
// true block-level token with .tag=='label'
function beginWrap(options: any, TokenConstructor: any) {
  const token = new TokenConstructor('checkbox_open', 'div', 0);
  token.attrs = [['class', options.divClass]];
  return token;
}

function endWrap(TokenConstructor: any) {
  const token = new TokenConstructor('checkbox_close', 'div', -1);
  // token.content = '</label>';
  return token;
}

function isInline(token: any) {
  return token.type === 'inline';
}

function isParagraph(token: any) {
  return token.type === 'paragraph_open';
}

function isListItem(token: any) {
  return token.type === 'list_item_open';
}

function startsWithTodoMarkdown(token: any) {
  // The leading whitespace in a list item (token.content) is already trimmed off by markdown-it.
  // The regex below checks for '[ ] ' or '[x] ' or '[X] ' at the start of the string token.content,
  // where the space is either a normal space or a non-breaking space (character 160 = \u00A0).
  return /^\[[xX \u00A0]\][ \u00A0]/.test(token.content);
}


// Remember old renderer, if overridden, or proxy to default renderer
const defaultRender = MD.normalizeLink;

MD.normalizeLink = (url) => {
  return defaultRender(url);
};
